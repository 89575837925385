import { gql } from '@apollo/client'

export const filterQuery = gql`
  query FilterQuery {
    demoFilterOptions {
      categories # string[]
      basedOnPapers # boolean[]
      yearOfPublication # number[]
    }
  }
`
export const demoSearchQuery = gql`
  query AllDemos(
    $query: String
    $pageOffset: Int!
    $pageLimit: Int!
    $filterCategories: [String!]
    $filterYearsOfPublication: [Int!]
    $filterBasedOnPapers: Boolean
    $sorts: [DemoSortableFieldSortInput!]
  ) {
    demos(
      page: { offset: $pageOffset, limit: $pageLimit }
      input: {
        query: $query
        filter: {
          categories: $filterCategories
          yearsOfPublication: $filterYearsOfPublication
          basedOnPapers: $filterBasedOnPapers
        }
        sorts: $sorts
      }
    ) {
      items {
        id
        link
        slug
        thumbnail
        title
      }
      totalItems
    }
  }
`

export const relatedContentByTypeAndSlug = gql`
  query RelatedContentByTypeAndSlug(
    $filterType: [ContentType!]
    $contentType: ContentType!
    $slug: String!
  ) {
    content(type: $contentType, slug: $slug) {
      ... on Content {
        related(count: 10, contentTypes: $filterType) {
          ... on Content {
            id
            title
            thumbnail
            slug
            link
            publishedAt
          }
        }
      }
    }
  }
`

export const searchFiltersQuery = gql`
  query SearchFilterOptions($contentTypes: [ContentType!]) {
    searchFilterOptions {
      challenge {
        categories
        difficulties
      }
      demo {
        categories
      }
      blog {
        categories
      }
      profile {
        socialAccountTypes
      }
      yearsOfPublication(contentTypes: $contentTypes)
    }
  }
`

export const searchQuery = gql`
  query Search(
    $query: String
    $pageOffset: Int!
    $pageLimit: Int!
    $contentTypes: [ContentType!]
    $years: [Int!]
    $specificFilters: SearchSpecificFilterInput
    $sorts: [SearchSortableFieldSortInput!]
  ) {
    search(
      input: {
        query: $query
        sorts: $sorts
        genericFilters: { yearsOfPublication: $years }
        contentTypes: $contentTypes
        specificFilters: $specificFilters
      }
      page: { offset: $pageOffset, limit: $pageLimit }
    ) {
      totalItems
      items {
        ... on Content {
          type
          id
          slug
          link
          title
          thumbnail
        }
        ... on StandaloneChallengeContent {
          standaloneChallenge {
            difficulty
          }
        }
        ... on ProfileContent {
          profile {
            handle
            firstName
            lastName
          }
        }
      }
    }
  }
`
