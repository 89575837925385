export const getImageByTypeFromMetaData = (
  previewImages: Queries.Frontmatter['previewImages'],
  type: string
) => {
  const image = previewImages
    ? previewImages.find((image) => image?.type === type)
    : null
  return image && image.uri ? image.uri : ''
}

export const GetElementsByAttribute = (
  tag: string,
  attributeName: string,
  attributeValue: string,
  htmlString: string
) => {
  const elementRegex = new RegExp(
    `<${tag}[^>]*${attributeName}[\\s]?=[\\s]?['"]${attributeValue}[^'"]*['"][\\s\\S]*?</${tag}>`,
    'gm'
  )
  const elements = htmlString.match(elementRegex)
  return elements
}

export const getRelatedDemoThumbnail = (imageUri: string, siteUrl: string) => {
  return `${imageUri.includes(siteUrl) ? '' : siteUrl}${imageUri}`
}
