import { useQuery } from '@apollo/client'
import {
  filterQuery,
  demoSearchQuery,
  relatedContentByTypeAndSlug,
  searchFiltersQuery,
  searchQuery,
} from './graphql'
import {
  ContentType,
  ProfileSocialAccountType,
  SortFilterEnum,
} from 'shared-utilities'

export const ALL_CONTENT_FILTER = [
  ContentType.BLOG,
  ContentType.CHALLENGE,
  ContentType.DEMO,
]

/* Filters */
export type FilterQueryType = {
  demoFilterOptions: {
    categories: string[]
    basedOnPapers: boolean[]
    yearOfPublication: number[]
  }
}

export const useFilterQuery = () => {
  const { loading, error, data } = useQuery<FilterQueryType>(filterQuery)
  if (error) {
    console.error(error)
  }
  return { loading, error, data }
}

/* Demos */
export type Demo = {
  id: string
  link: string
  slug: string
  thumbnail: string
  title: string
  related: {
    items: {
      id: string
      link: string
      slug: string
      thumbnail: string
      title: string
    }[]
  }
}

export type DemoSearchQueryType = {
  demos: {
    items: Demo[]
    totalItems: number
  }
}

type DemoSearchQueryVariables = {
  query: string
  pageOffset: number
  pageLimit: number
  filterCategories?: string[]
  filterYearsOfPublication?: number[]
  filterBasedOnPapers?: boolean
  sorts: {
    field: SortFilterEnum
    desc: boolean
  } | null
}

export const useDemoSearchQuery = ({
  query,
  pageOffset,
  pageLimit,
  filterCategories,
  filterYearsOfPublication,
  filterBasedOnPapers,
  sorts,
}: DemoSearchQueryVariables) => {
  const { loading, error, data } = useQuery<
    DemoSearchQueryType,
    DemoSearchQueryVariables
  >(demoSearchQuery, {
    variables: {
      query,
      pageOffset,
      pageLimit,
      filterCategories,
      filterYearsOfPublication,
      filterBasedOnPapers,
      sorts,
    },
  })
  if (error) {
    console.error(error)
  }
  return { loading, error, data }
}

/* Related Demos */
export type RelatedDemosByDemoSlugQueryType = {
  content: {
    related: {
      link: string
      thumbnail: string
      title: string
      slug: string
      publishedAt: string
    }[]
  }
}

export const useRelatedContentQuery = (
  slug: string,
  type: ContentType,
  filterType: ContentType[]
) => {
  const { loading, error, data } = useQuery<RelatedDemosByDemoSlugQueryType>(
    relatedContentByTypeAndSlug,
    {
      variables: {
        slug,
        contentType: type,
        filterType: filterType,
      },
    }
  )
  if (error) {
    console.error(error)
  }
  return { loading, error, data }
}

export type SearchFilterOptions = {
  searchFilterOptions: {
    yearsOfPublication: number[]
    challenge: {
      categories: string[]
      difficulties: string[]
    }
    demo: {
      categories: string[]
    }
    blog: {
      categories: string[]
    }
    profile: {
      socialAccountTypes: ProfileSocialAccountType[]
    }
  }
}

export const useSearchFilters = (contentType: ContentType | null) => {
  return useQuery<SearchFilterOptions>(searchFiltersQuery, {
    variables: {
      contentTypes: contentType ? [contentType] : ALL_CONTENT_FILTER,
    },
  })
}

export type SearchContentResult = {
  type: ContentType
  id: string
  slug: string
  link: string
  title: string
  thumbnail: string
  standaloneChallenge?: {
    difficulty?: 'BEGINNER' | 'INTERMEDIATE' | 'ADVANCED'
  }
  profile?: {
    handle: string
    firstName: string | null
    lastName: string | null
  }
}

export type SearchContent = {
  items: SearchContentResult[]
  totalItems: number
}

type DemoSpecificFilterInput = {
  demoFilters: {
    categories: string[]
    basedOnPapers: boolean
  }
} | null

type BlogSpecificFilterInput = {
  blogFilters: {
    categories: string[]
  }
} | null

type ChallengeSpecificFilterInput = {
  challengeFilters: {
    categories: string[]
    difficulties: string[]
  }
} | null

type ProfileSpecificFilterInput = {
  profileFilters: {
    socialAccountTypes: ProfileSocialAccountType[]
  }
} | null

export type SearchQueryVariables = {
  contentTypes: ContentType[] | null
  query: string | null
  pageOffset: number
  pageLimit: number
  years: number[] | null
  specificFilters:
    | DemoSpecificFilterInput
    | BlogSpecificFilterInput
    | ChallengeSpecificFilterInput
    | ProfileSpecificFilterInput
    | null
  sorts?: {
    field: SortFilterEnum
    desc: boolean
  } | null
}

export type SearchQueryItemsReturn = {
  search?: SearchContent
}

export const useSearchQuery = ({
  query,
  pageOffset,
  pageLimit,
  years,
  specificFilters,
  sorts,
  contentTypes,
}: SearchQueryVariables) => {
  const { loading, error, data } = useQuery<
    SearchQueryItemsReturn,
    SearchQueryVariables
  >(searchQuery, {
    variables: {
      query,
      pageOffset,
      pageLimit,
      contentTypes,
      years,
      specificFilters,
      sorts,
    },
  })
  if (error) {
    console.error(error)
  }
  return { loading, error, data }
}
