import { useState, useEffect } from 'react'
import { PageProps, graphql } from 'gatsby'
import { parseISO, format } from 'date-fns'
import { usePathPrefix } from '../../../hooks/usePathPrefix'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'
import { getPathname } from '../../../utils/url_helpers'
import { GetElementsByAttribute } from '../../../utils/demo_helpers'
import { ILinksListItem } from '../../../components/ContentSidebar/ContentSidebar'
import { IContentAuthor } from '../../../types'
import { getAuthorObjectList } from '../../../utils/author_helper'
import Seo from '../../../components/seo'
import IndividualDemoLayout from './IndividualDemoLayout'
import DemoMain from '../../../components/DemoMain/DemoMain'
import './compiled-styles.scss'
import './style.scss'

const DEFAULT_DISCUSSION_FORUM_URL = `https://discuss.pennylane.ai/c/demos/25`

export default function DemoTemplate({
  data,
  pageContext,
}: PageProps<Queries.DemoPageByIdQuery>) {
  const pathPrefix = usePathPrefix()
  const markdownRemark = data.markdownRemark
  const html = markdownRemark?.html || ''
  const frontmatter = markdownRemark?.frontmatter

  /** Converts a stringified anchor tag element to an anchor link */
  const formatHeaderLink = (anchorTagString: string) => {
    const anchorRegex = new RegExp(`href="(.*?)"`, 'gm')
    const href = anchorRegex.exec(anchorTagString)
    if (href && href[1]) return `#${href[1].split('#')[1]}`
    return ''
  }

  /** Extracts text content from stringified header element */
  const formatHeaderTitle = (title: string) => {
    return title.replace(/<[^>]+>/g, '').slice(0, -1)
  }

  /** Extracts page headers from demo HTML as stringified header elements */
  const getDemoHeaders = (htmlString: string) => {
    return htmlString.match(/<h(.)>.*?<\/h\1>/g)
  }

  const pageHeaders = getDemoHeaders(html)
  /** Removes the <h1> tag from the list of headers since this is not needed for the ContentSidebar */
  const sectionHeaders = pageHeaders ? [...pageHeaders].slice(1) : null

  /** Format the section headers extracted from HTML to use them in the <ContentSidebar /> component */
  const formatSectionHeaders = (sectionHeaders: string[] | null) => {
    if (sectionHeaders) {
      /** Array of demo section headers, organized into headers and sub-headers. */
      const sortedHeaders: ILinksListItem[] = []
      sectionHeaders.forEach((header) => {
        if (
          /** If the header is an <h3> tag, add it as a subheader of the previous <h2> tag. */
          sortedHeaders.length &&
          Number(header.substring(2)[0]) >
            Number(
              sortedHeaders[sortedHeaders.length - 1].title.substring(2)[0]
            )
        ) {
          sortedHeaders[sortedHeaders.length - 1].items?.push({
            url: header,
            title: header,
          })
        } else {
          /** If the header is an <h2> tag, push it to the array of section headers */
          sortedHeaders.push({
            url: header,
            title: header,
            items: [],
          })
        }
      })
      /** Formats the title and url strings of the headers to be used in the <ContentSidebar /> component */
      const formattedHeaders = sortedHeaders.map((header) => {
        return {
          url: formatHeaderLink(header.url),
          title: formatHeaderTitle(header.title),
          items: header.items
            ? header.items.map((item) => {
                return {
                  url: formatHeaderLink(item.url),
                  title: formatHeaderTitle(item.title),
                }
              })
            : [],
        }
      })
      return formattedHeaders
    } else return []
  }

  const [totalTime, setTotalTime] = useState<string[]>([])
  const [inlineScriptSources, setInlineScriptSources] = useState<string[]>([])
  const [currentExecutingScriptIndex, setCurrentExecutingScriptIndex] =
    useState<number>(0)

  const authors = pageContext['authors'] || []
  const authorObjectList: IContentAuthor[] = getAuthorObjectList(authors)

  const getTotalTime = () => {
    const totalTime: string[] = []
    const totalTimeHTML = GetElementsByAttribute(
      'p',
      'class',
      'sphx-glr-timing',
      html
    )
    if (totalTimeHTML) totalTime.push(totalTimeHTML[0])
    setTotalTime(totalTime)
  }

  useEffect(() => {
    getTotalTime()
  }, [])

  const getDownloadHref = (download: 'python' | 'jupyter') => {
    const elementHTML = GetElementsByAttribute(
      'div',
      'class',
      `sphx-glr-download sphx-glr-download-${download} docutils container`,
      html
    )
    const elementLinkHTML = GetElementsByAttribute(
      'a',
      'class',
      'reference download internal',
      elementHTML ? elementHTML[0] : ''
    )
    if (elementLinkHTML) {
      const hrefArray = elementLinkHTML[0].match(/href="([^"]*)/)
      if (hrefArray) {
        return hrefArray[1].replace(pathPrefix, '')
      } else return ''
    } else return ''
  }

  const getBibTexCitation = () => {
    const authorNames = authorObjectList.map((author) => author?.name || '')
    const authors = authorNames.map((name) => {
      return name
        ? name.replace(/\b(\w)/g, (match) => {
            return match.toUpperCase()
          })
        : ''
    })
    const formattedAuthors = authors?.join(
      authorNames.length === 1 ? '' : ' and '
    )

    const date = new Date(frontmatter?.date_of_publication || '')
    let year = 0
    let month = 0

    if (!isNaN(date.getTime())) {
      year = date.getFullYear()
      month = date.getMonth() + 1
    }
    // Format the month as a two-digit string if necessary (e.g., '01' for January)
    const formattedMonth = month && month < 10 ? `0${month}` : month

    const firstAuthor = authors[0]?.split(' ').join('')
    const currentDate = new Date().toLocaleDateString('sv-SE')

    return `@misc{${firstAuthor + year},
      title = "${frontmatter?.title}",
      author = "${formattedAuthors}",
      year = "${year}",
      month = "${formattedMonth}",
      journal = "PennyLane Demos",
      publisher = "Xanadu",
      howpublished = "\\url{https://pennylane.ai${getPathname()}}",
      note = "Date Accessed: ${currentDate}"
    }`
  }

  const getAllScriptTags = () => {
    const scriptRegex = new RegExp(`<script.*?src="(.*?)"`, 'gm')
    const inlineScripts: string[] = []
    let inlineScriptInstance: RegExpExecArray | null = null
    while ((inlineScriptInstance = scriptRegex.exec(html))) {
      inlineScripts.push(inlineScriptInstance[1])
    }
    return inlineScripts
  }

  useEffect(() => {
    setInlineScriptSources(getAllScriptTags())
  }, [])

  return (
    <IndividualDemoLayout
      mainSection={
        <DemoMain
          title={frontmatter?.title || ''}
          previewImages={frontmatter?.previewImages || []}
          date_of_publication={frontmatter?.date_of_publication || ''}
          date_of_last_modification={
            frontmatter?.date_of_last_modification || ''
          }
          html={html}
          inlineScriptSources={inlineScriptSources}
          currentExecutingScriptIndex={currentExecutingScriptIndex}
          setCurrentExecutingScriptIndex={setCurrentExecutingScriptIndex}
          contentAuthors={authorObjectList}
          totalTime={totalTime}
          discussionForumUrl={
            frontmatter?.discussion_forum_url || DEFAULT_DISCUSSION_FORUM_URL
          }
        />
      }
      linksList={formatSectionHeaders(sectionHeaders)}
      downloads={{
        python: getDownloadHref('python'),
        notebook: getDownloadHref('jupyter'),
        // If we don't have a slug, we send them to the directory
        github: `https://github.com/PennyLaneAI/qml/blob/master/demonstrations/${
          frontmatter?.slug ? `${frontmatter.slug}.py` : ''
        }`,
      }}
      BibTex={getBibTexCitation()}
      title={frontmatter?.title || ''}
      slug={frontmatter?.slug || ''}
      categories={frontmatter?.categories || []}
      hardware={frontmatter?.hardware}
      curatedRelatedContent={
        frontmatter?.relatedContent
          ?.map((content) => ({
            link: content?.id ? `/demos/${content.id}` : '',
            slug: content?.id || '',
            thumbnail: content?.previewImages?.[0]?.uri || '',
            title: content?.title || '',
            image: content?.previewImages?.[0]?.uri || '',
          }))
          .filter((content) => content.title) || []
      }
    />
  )
}

export const Head = ({
  data,
  pageContext,
}: PageProps<Queries.DemoPageByIdQuery>) => {
  const { siteUrl } = useSiteMetadata()

  const frontmatter = data.markdownRemark?.frontmatter
  const authors = pageContext['authors'] || []

  const getHighwireTags = () => {
    if (
      frontmatter?.date_of_publication &&
      frontmatter.title &&
      authors?.length
    )
      return (
        <>
          <meta name="citation_title" content={frontmatter.title} />
          {authors.map((author, i) => (
            <meta
              key={`citation-author-${i}`}
              name="citation_author"
              content={
                author?.name ||
                `${author.firstName || ''}${
                  author.lastName ? ` ${author.lastName}` : ''
                }`
              }
            />
          ))}
          <meta
            name="citation_publication_date"
            content={`${format(
              parseISO(frontmatter.date_of_publication),
              'yyyy/MM/dd'
            )}`}
          />
          <meta name="citation_journal_title" content="PennyLane Demos" />
          <meta name="citation_publisher" content="Xanadu" />
        </>
      )
    else return <></>
  }

  const getGoogleStructureTags = () => {
    if (
      frontmatter?.date_of_publication &&
      frontmatter.date_of_last_modification &&
      frontmatter.title &&
      authors?.length
    ) {
      const authorNames = authors.map((author) => ({
        '@type': 'Person',
        name:
          author?.name ||
          `${author?.firstName || ''}${
            author?.lastName ? ` ${author?.lastName}` : ''
          }`,
      }))

      const images = frontmatter.previewImages?.map(
        (image) =>
          `${image?.uri?.includes(siteUrl) ? '' : siteUrl}${image?.uri}`
      )

      return (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: frontmatter.title,
            image: images,
            datePublished: frontmatter.date_of_publication,
            dateModified: frontmatter.date_of_last_modification,
            author: authorNames,
          })}
        </script>
      )
    }
    return <></>
  }

  return (
    <Seo
      title={`${frontmatter?.title} | PennyLane Demos`}
      description={frontmatter?.meta_description || ''}
      image={frontmatter?.meta_image || ''}
    >
      <>
        {getHighwireTags()}
        {getGoogleStructureTags()}
      </>
    </Seo>
  )
}

export const demoQuery = graphql`
  query DemoPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
        meta_description
        meta_image
        date_of_publication
        date_of_last_modification
        discussion_forum_url
        previewImages {
          type
          uri
        }
        categories
        relatedContent {
          id
          title
          previewImages {
            type
            uri
          }
        }
        hardware {
          id
          link
          logo
        }
      }
    }
  }
`
