import { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { getCurrentUrl } from '../../utils/url_helpers'
import copy from 'copy-to-clipboard'
import './style.scss'

const DemoShareLinks = (props: {
  totalTime: string[]
  discussionForumUrl: string
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false)

  const twitterShareUrl = `https://twitter.com/share?url=${getCurrentUrl()}`
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${getCurrentUrl()}`

  const handleShareButtonClick = (shareUrl: string) => {
    window.open(shareUrl, '_blank', 'noopener,noreferrer')
  }

  const handleCopyButtonClick = async () => {
    copy(window.location.href)
    setShowConfirmation(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfirmation(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [showConfirmation])

  return (
    <>
      <span
        className="totalTime"
        data-testid="total-time"
        dangerouslySetInnerHTML={{ __html: props.totalTime[0] }}
      />
      <div className="Links">
        <div className="Links__share">
          <p>Share demo</p>
          <ul className="Links__share__list">
            <li>
              <button
                onClick={() => handleShareButtonClick(twitterShareUrl)}
                aria-label="Share demo on Twitter"
                className="Share__button mr-2 flex items-center"
                data-testid="twitter-share"
              >
                <i className="bx bxl-twitter"></i>
              </button>
            </li>
            <li>
              <button
                onClick={handleCopyButtonClick}
                aria-label="Copy demo URL"
                className="Copy__button mr-2"
                data-testid="copy-url"
                data-tooltip-id="copy-url-button"
              >
                <i className="bx bx-link-alt"></i>
              </button>
              <Tooltip
                id="copy-url-button"
                data-testid="copy-url-tooltip"
                content="Link copied"
                isOpen={showConfirmation}
                className="Copy__tooltip"
              />
            </li>
            <li>
              <button
                onClick={() => handleShareButtonClick(linkedInShareUrl)}
                aria-label="Share demo on LinkedIn"
                className="Share__button"
                data-testid="linkedin-share"
              >
                <i className="bx bxl-linkedin"></i>
              </button>
            </li>
          </ul>
        </div>
        <div>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href={props.discussionForumUrl}
            className="Forum___button"
            aria-label="Pennylane forum"
            data-testid="forum-button"
            target="_blank"
            rel="noopener"
          >
            Ask a question on the forum{'   '}
            <i className="fa-solid fa-arrow-up-right-from-square ml-2"></i>
          </a>
        </div>
      </div>
    </>
  )
}

export default DemoShareLinks
